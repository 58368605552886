<template>
    <zw-sidebar @shown="shown" :title="$t('common.title.event')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col cols="12">
                        <zw-select-group v-model="form.action"
                                         :options="getTodoActions()"
                                         validate="required"
                                         name="todo.action"
                        ></zw-select-group>
                    </b-col>
                    <b-col cols="12">
                        <zw-select-group v-model="form.sub_action_id"
                                         :options="getTodoSubActions()[form.action]"
                                         name="todo.sub_action"
                        ></zw-select-group>
                    </b-col>
                    <b-col cols="12">
                        <label>{{ $t(labelPrefix + 'description') }}</label>
                        <zw-ckeditor
                            v-model="form.description"
                            name="description"
                        />
                    </b-col>
                    <b-col cols="12">
                        <zw-date-group v-model="form.date"
                                       name="date"
                                       :label-prefix="labelPrefix"
                                       :maxDate="currentDate()"
                        ></zw-date-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <b-row>
                            <b-col cols="12">
                                <zw-switch-group v-model="form.todo"
                                                 :label-prefix="labelPrefix"
                                                 name="todo"
                                                 :falseValue="false"
                                                 :trueValue="true"
                                                 @input="todoEnabled"

                                />
                            </b-col>
                            <template v-if="form.todo==true">
                                <b-col cols="12">
                                    <zw-select-group
                                        v-model="form.todo_action"
                                        :options="getTodoActions()"
                                        :label-prefix="labelPrefix"
                                        name="action"
                                        size="s"
                                        validate="required"
                                    ></zw-select-group>
                                </b-col>
                                <b-col cols="12">
                                    <zw-select-group
                                        v-model="form.todo_sub_action"
                                        :options="getTodoSubActions()[form.todo_action]"
                                        :label-prefix="labelPrefix"
                                        name="action"
                                        size="s"
                                    ></zw-select-group>
                                </b-col>
                                <b-col cols="4">
                                    <b-dropdown style="width: 100%" :text="getOptionText(form.todoDate)">
                                        <b-dropdown-item v-for="(option,key) in getDateOptions()"
                                                         :key="key"
                                                         @click="selectDateOption(key)"
                                        >{{ option }}
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </b-col>
                                <b-col cols="4" v-if="form.todoDate=='custom'">
                                    <zw-date-group v-model="form.todo_custom_date"
                                                   name="date"
                                                   disable-label
                                                   size="s"
                                    ></zw-date-group>
                                </b-col>
                                <b-col cols="2">
                                    <zw-new-time-group v-model="form.todoTime"
                                                       name="time"
                                                       :label-prefix="labelPrefix"
                                                       disable-label
                                    ></zw-new-time-group>
                                </b-col>
                                <b-col cols="4">
                                    <zw-select-group v-model="form.todo_user_id"
                                                     :options="users"
                                                     name="todo.user"
                                                     text-field="fullname"
                                                     value-field="id"
                                                     size="s"
                                                     disable-label
                                                     validate="required"
                                    ></zw-select-group>
                                </b-col>
                                <b-col cols="12">
                                    <zw-input-group v-model="form.todo_title"
                                                    name="todo_title"
                                                    :label-prefix="labelPrefix"
                                                    validate="required"
                                    />
                                </b-col>
                                <b-col cols="12">
                                    <zw-ckeditor
                                        v-model="form.todo_description"
                                        name="description"
                                    />
                                </b-col>
                            </template>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <b-button class="mt-2" block @click="save" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import moment from "moment";
import {mapGetters} from "vuex";
import commonSave from "@/bundles/erika_common_bundle/mixins/common-save";
import todos from "@/bundles/erika_common_bundle/mixins/todos";

export default {
    name: 'EventModal',
    mixins: [commonSave, todos],
    data() {
        return {
            payload: {},
            users: [],
            form: {
                id: null,
                event_id: null,
                type: 'customer',
                todo: 0,
                todoTime: '12:00',
                todo_custom_date: null,
                todoDate: 'tomorrow',
            },
            loading: false,
            labelPrefix: 'common.event.label.',
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getTodoActions', 'getTodoSubActions', 'getEventsDateOptions', 'getMe']),
        ...mapGetters('Users', ['getUsersList']),
        shown() {
            this.loading = false
            this.$store.dispatch('Users/fetchUsersList').then(() => {
                this.users = this.getUsersList()
            })
            this.form.todo_custom_date = moment().format('YYYY-MM-DD')
            this.form.id = this.payload.id
            if (this.payload.event) {
                this.form.event_id = this.payload.event.id
                this.form.action = this.payload.event.event
                this.form.sub_action_id = this.payload.event.data.sub_action_id
                this.form.description = this.payload.event.data.description
                this.form.date = this.payload.event.created_at
            }

        },
        selectDateOption(option) {
            this.form.todoDate = option
        },
        save() {
            let [hours, minutes] = this.form.todoTime.split(':').map(Number);
            hours -= moment().utcOffset() / 60

            this.form.todoTime = hours + ':' + minutes

            axios.post(window.apiUrl + '/events/create', this.form, {'skip_loading': true})
                .then(result => {
                    this.commonAfterSave(result, this.sidebar)
                }, () => {
                    this.loading = false
                })
        },
        currentDate() {
            const today = moment();
            return today.format('YYYY-MM-DD');
        },
        todoEnabled(val) {
            if (val) {
                this.form.todo_action = this.form.action
                let me = this.getMe();
                this.form.todo_user_id = me.id
            }
        }
    },
}
</script>