import moment from "moment/moment";

export default {
    data() {
        return {}
    },
    methods: {
        getDateOptions() {
            let values = {};
            this.getEventsDateOptions().forEach((key) => {
                values[key] = this.getOptionText(key)
            })

            return values
        },
        addBusinessDays(date, days) {
            let result = moment(date);
            let addedDays = 0;

            while (addedDays < days) {
                result.add(1, 'days');
                if (result.isoWeekday() < 6) {
                    addedDays++;
                }
            }

            return result;
        },
        getOptionText(key) {
            let date = moment()
            if (key == 'today') {
                date = moment()
            }
            if (key == 'tomorrow') {
                date = moment().add('1', 'day')
            }
            if (key == '1_day') {
                date = this.addBusinessDays(moment(), 1)
            }
            if (key == '2_days') {
                date = this.addBusinessDays(moment(), 2)
            }
            if (key == '3_days') {
                date = this.addBusinessDays(moment(), 3)
            }
            if (key == '4_days') {
                date = this.addBusinessDays(moment(), 4)
            }
            if (key == '5_days') {
                date = this.addBusinessDays(moment(), 5)
            }
            if (key == '6_days') {
                date = this.addBusinessDays(moment(), 6)
            }
            if (key == '1_week') {
                date = moment().add('1', 'week')
            }
            if (key == '2_weeks') {
                date = moment().add('2', 'week')
            }
            if (key == '3_weeks') {
                date = moment().add('3', 'week')
            }
            if (key == '1_month') {
                date = moment().add('1', 'month')
            }
            if (key == '2_months') {
                date = moment().add('2', 'month')
            }
            if (key == '3_months') {
                date = moment().add('3', 'month')
            }
            if (key == 'custom') {
                date = moment(this.form.todo_custom_date, 'YYYY-MM-DD')
            }
            if (key != 'custom' && key != 'today') {
                if (date.weekday() == 6) {
                    date.add('2', 'day')
                }
                if (date.weekday() == 0) {
                    date.add('1', 'day')
                }
            }

            date = date.format('DD MMMM')
            return this.$t('common.event.date_options.' + key) + ' (' + date + ')'
        },
    },
}